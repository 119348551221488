
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import { BackofficeLocalizationClient, LocalizationClient, LocalResourceContryClient } from './services/Services';
import { LoginServices } from './services/LoginServices';
import { LocalizationServices } from './services/LocalizationServices';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        SpinnerContainer
    }
})
export default class App extends Vue {

    loaded = false;

    created(){
        store.state.loggedUser = StorageServices.getLoggedUser();
        this.loadGenders();
        this.loadProfileType();

        Promise.all([
            BackofficeLocalizationClient.getBackofficeDictionary(),
            LocalizationClient.getEnabledLanguages(),
            LocalResourceContryClient.getExtendedCountries(),
        ]).then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
            store.state.consts.extendedCountries = x[2];
            this.loaded = true;
        })
    }

    loadProfileType(){
        store.state.consts.profileType = [];
        store.state.consts.profileType.push({ identifier: VM.ProfileResultType.Blu, text: LocalizationServices.getProfileTypeTranslation(VM.ProfileResultType.Blu)})
        store.state.consts.profileType.push({ identifier: VM.ProfileResultType.Giallo, text: LocalizationServices.getProfileTypeTranslation(VM.ProfileResultType.Giallo)})
        store.state.consts.profileType.push({ identifier: VM.ProfileResultType.Verde, text: LocalizationServices.getProfileTypeTranslation(VM.ProfileResultType.Verde)})
        store.state.consts.profileType.push({ identifier: VM.ProfileResultType.Rosso, text: LocalizationServices.getProfileTypeTranslation(VM.ProfileResultType.Rosso)})
    }

    loadGenders(){
        var genderList: OM.TextIdentifier[] = [];
        var ownGenderList: OM.TextIdentifier[] = [];

        var keys = Object.keys(OM.Gender);
        keys.slice(0, keys.length / 2).forEach( el => {
            var vm = new OM.TextIdentifier();
            vm.identifier = el;
            vm.text = LocalizationServices.getLookingForTranslation(el);

            genderList.push({...vm});

            vm.text = LocalizationServices.getOwnGenderTranslation(el);
            ownGenderList.push(vm)
        })

        store.state.consts.genderList = genderList;
        store.state.consts.ownGenderList = ownGenderList;
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }

}
