
import { LocalizationServices } from "@/services/LocalizationServices";
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import store from "@/store";
import * as OM from "@/Model";

@Options({})
export default class LocalizedInput extends Vue {
    @Prop({
        type: [OM.LocalizedValue, Object],
        default: function () { return { values: {} } }
    }) modelValue: OM.LocalizedValue;
    @Prop({ default: 'text' }) type: string;
    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: 1 }) tabIndex: number;
    @Prop({ default: "" }) labelKey: string;
    @Prop({ default: "" }) labelValue: string;

    locales = null;

    created() {
        this.locales = store.state.consts.enabledLanguages;
    }

    setInputLocale(language: string) {
        LocalizationServices.SetLanguage(language);
    }

    get selectedLocale() {
        return store.state.selectedLanguage;
    }

    get selectedInput() {
        var input = this.modelValue.values[this.selectedLocale];
        if (!input) {
            this.modelValue.values[this.selectedLocale] = "";
        }
        return this.modelValue.values[this.selectedLocale];
    }

    set selectedInput(value: string) {
        this.modelValue.values[this.selectedLocale] = value;
    }
}
